import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-user", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "primary",
                icon: "mdi-account",
                title: _vm.page_title(),
              },
            },
            [
              _c(
                VForm,
                {
                  ref: "form",
                  staticClass: "mt-5",
                  attrs: { "lazy-validation": _vm.lazy },
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.edit_user()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.$t("name"),
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.user.Name,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "Name", $$v)
                              },
                              expression: "user.Name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.$t("last_name"),
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.user.LastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "LastName", $$v)
                              },
                              expression: "user.LastName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.partners,
                              loading: _vm.partnersLoading,
                              label: _vm.$tc("partner", 1),
                              "item-text": "Email",
                              "item-value": "Id",
                              color: "black",
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.user.PartnerId,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "PartnerId", $$v)
                              },
                              expression: "user.PartnerId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##"],
                                expression: "['###.###.###-##']",
                              },
                            ],
                            attrs: {
                              label: _vm.$t("cpf"),
                              rules: [_vm.required, _vm.social_number_valid],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.user.SocialNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "SocialNumber", $$v)
                              },
                              expression: "user.SocialNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.$t("email"),
                              rules: [_vm.required],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.user.Email,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "Email", $$v)
                              },
                              expression: "user.Email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##/##/####"],
                                expression: "['##/##/####']",
                              },
                            ],
                            attrs: {
                              label: _vm.$t("birth_date"),
                              "prepend-inner-icon": "mdi-calendar",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.user.Birth = _vm.gs.parseDate(_vm.date)
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["#####-###"],
                                expression: "['#####-###']",
                              },
                            ],
                            staticClass: "purple-input",
                            attrs: {
                              placeholder: "00000-000",
                              type: "tel",
                              rules: [_vm.required],
                              label: _vm.$t("post_code"),
                            },
                            on: { keyup: _vm.post_code_changed },
                            model: {
                              value: _vm.user.PostCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "PostCode", $$v)
                              },
                              expression: "user.PostCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            staticClass: "purple-input",
                            attrs: {
                              label: _vm.$t("state"),
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.user.State,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "State", $$v)
                              },
                              expression: "user.State",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            staticClass: "purple-input",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("city"),
                            },
                            model: {
                              value: _vm.user.City,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "City", $$v)
                              },
                              expression: "user.City",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "8" } },
                        [
                          _c(VTextField, {
                            staticClass: "purple-input",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("address"),
                            },
                            model: {
                              value: _vm.user.Address,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "Address", $$v)
                              },
                              expression: "user.Address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c(VTextField, {
                            staticClass: "purple-input",
                            attrs: {
                              rules: [_vm.required],
                              label: _vm.$t("number"),
                            },
                            model: {
                              value: _vm.user.AddressNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "AddressNumber", $$v)
                              },
                              expression: "user.AddressNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c(VTextField, {
                            staticClass: "purple-input",
                            attrs: { label: _vm.$t("complement") },
                            model: {
                              value: _vm.user.AddressComplement,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "AddressComplement", $$v)
                              },
                              expression: "user.AddressComplement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pb-0", attrs: { cols: "12", md: "6" } },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("invest_value")))])]
                      ),
                      _c(
                        VCol,
                        { staticClass: "pb-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("selected_portfolio"))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pt-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c("money", {
                            staticClass: "register-text-field",
                            class: {
                              "invalid-field":
                                !_vm.user.InvestValue ||
                                _vm.user.InvestValue <
                                  _vm.portfolioSelected.MinimumValue,
                            },
                            attrs: {
                              prefix: "R$",
                              thousand: ".",
                              thousands: ".",
                              decimal: ",",
                            },
                            model: {
                              value: _vm.user.InvestValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "InvestValue", $$v)
                              },
                              expression: "user.InvestValue",
                            },
                          }),
                          !_vm.portfolioLoading &&
                          _vm.user.InvestValue <
                            _vm.portfolioSelected.MinimumValue
                            ? _c(
                                "p",
                                {
                                  staticClass: "mb-4 mt-n4 ml-2",
                                  staticStyle: {
                                    color: "red",
                                    "font-size": "10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("alpha_register_minimum", {
                                          value:
                                            _vm.portfolioSelected
                                              .MinimumValueLabel,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "pt-0", attrs: { cols: "12", md: "6" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.portfolioOptions,
                              loading: _vm.portfolioLoading,
                              "item-text": "number",
                              color: "black",
                              "return-object": "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.required],
                              readonly: "",
                            },
                            model: {
                              value: _vm.portfolioSelected,
                              callback: function ($$v) {
                                _vm.portfolioSelected = $$v
                              },
                              expression: "portfolioSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticClass: "pl-0 dxa_modal_actions" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            loading: _vm.saveLoading,
                            type: "submit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: { color: "secondary", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("go_back")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }